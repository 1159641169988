
import HomePage from '../pages/home.vue';
import StallagesPage from '../pages/stallages.vue';
import StallagePage from '../pages/stallage.vue';
import ReportPage from '../pages/report.vue';
import PrinterPage from '../pages/printer.vue';
import ReportsPage from '../pages/reports.vue';

import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/printer',
    component: PrinterPage,
  },
  {
    path: '/stallages/',
    component: StallagesPage,
    master: true,
    detailRoutes: [
      {
        path: '/stallages/:id/',
        component: StallagePage,
      },
      {
        path: '/stallages/:id/:action',
        component: StallagePage,
      },
    ]
  },
  {
    path: '/reports/',
    component: ReportsPage,
    master: true,
    detailRoutes: [
      {
        path: '/report/:id/',
        component: ReportPage,
      },
    ]
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
