<template>
    <f7-list-item
        v-show="show"
        ref="smartSelect"
        label="Strefa"
        placeholder="Wybierz strefę"
        class="zone-select"
        smart-select
        :smart-select-params="options"
    >
        <select v-model="zoneId">
            <option v-for="a in zones" :value="a.id" :key="a.id">
                {{nameForList(a)}}
            </option>
        </select>
        <div class="item-title item-label">Wybierz strefę</div>
    </f7-list-item>
</template>

<script>

export default {
    props: {
        alwaysVisible: Boolean,
    },
    data() {
        return {
            options: {
                openIn: 'popup',
                searchbar: true, //zones.length>10,
                searchbarPlaceholder: 'Wybierz strefę',
                popupCloseLinkText: 'Zamknij',
                searchbarDisableText: 'Anuluj',
                closeOnSelect: true,
                renderItem: this.renderItem
            }
        }
    },
    computed: {
        show() {
            return this.zones
        },
        zoneId: {
            get() {
                // hack for v-model + smart-select bug in f7
                if(this.$refs.smartSelect) {
                    this.$refs.smartSelect.f7SmartSelect.setValue(this.zone.id);
                }
                return this.zone.id;
            },
            set(id) {
                this.$store.commit('zone', this.zoneById(id))
            }
        },
        zone() {
            return this.$store.getters.zone;
        },
        zones() {
            return this.$store.getters.zones;
        }
    },
    methods: {
        zoneById(id) {
            return this.zones.find(zone => zone.id === id);
        },
        nameForList(zone) {
            return zone.name;
        },
        renderItem(item, index) {
            const zone = this.zoneById(item.value);
            return `
                    <li class="${item.className || ''} media-item">
                      <label class="item-${item.inputType} item-content">
                        <input type="${item.inputType}" name="${item.inputName}" value="${item.value}" ${item.selected ? 'checked' : ''}/>
                        <i class="icon icon-${item.inputType}"></i>
                        <div class="item-inner">
                          <div class="item-title-row">${zone.name}</div>
                          <div class="item-text zone-select-item-text">${zone.description}</div>
                        </div>
                      </label>
                    </li>
                `;
        }
    }
}
</script>

<style>
.zone-select .item-inner {
    display: block !important;
}

.zone-select .smart-select .item-after {
    max-width: 100%;
    padding-left: 0;
    padding-top: 10px;
}


.zone-select .item-inner:before {
    display: none !important;
    content: '';
}

.media-item .zone-select-item-text {
    max-height: none;
}


</style>
