import Vue from 'vue'
import Vuex from 'vuex'
import jwt from 'jsonwebtoken'

Vue.use(Vuex);

import axios from 'axios';

const http = axios.create({
    baseURL: apiUri,
});

const debug = process.env.NODE_ENV !== 'production';

const store  = new Vuex.Store({
    state: {
        user: {
            logged: false,
            token: null,
            payload: null,
            pin: false
        },
        printer: null,
        zone: null,
        zones: null,
        countries: [],
        stallages: {items: [], notLoaded: true},
        tiers: [],
        reports: {items: [], notLoaded: true},
        import: null,
        apiUri: apiUri
    },
    getters: {
        tierName: state => id => {
            let tiers = [];
            // concat tiers form all zones
            for(const zone of state.zones) {
                tiers = [...tiers, ...zone.tiers];
            }
            const tier = tiers.find(tier => tier.id===id );
            return tier ? tier.name : null;
        },

        pinCreated: state => {
            return state.user.pin;
        },

        userLogged: state => {
            return state.user.logged;
        },

        countries: state => {
            if(state.countries.length===0) {
                store.dispatch('countries');
            }
            return state.countries;
        },
        zone: state => {
            if(null===state.zones) {
                store.dispatch('zones');
            }
            return state.zone;
        },
        zones: state => {
            if(null===state.zones) {
                store.dispatch('zones');
            }
            return state.zones;
        },
        manyAccommodations: state => {
            return false;
        },
        stallages: state => {
            if(state.stallages.notLoaded) {
                store.dispatch('stallages');
            }
            return state.stallages;
        },
        stallage: state => id => {
            return store.getters.stallages.items.find(stallage => stallage.id === id);
        },
        stallageRequest: state => id => {
            return http.get('/' + id);
        },
        reports: state => {
            if(state.reports.notLoaded) {
                store.dispatch('reports');
            }
            return state.reports;
        },
        report: state => id => {
            return store.getters.reports.items.find(report => report.id === id)
        },
        printer: state => {
            return state.printer;
        }
    },
    actions: {
        zones({commit}) {
            http.get('/dictionaries').then(response => {
                commit('zones', response.data);
                commit('zone', response.data[0]);
            });
        },
        countries({commit}) {
            http.get('/countries').then(response => {
                commit('countries', response.data);
            });
        },
        stallages({commit}) {
            return http.get('/').then(response => {
                commit('stallages', response.data);
            });
        },
        filteredStallages({commit}, {phrase}) {
            const config = {
                params: {
                    phrase: phrase
                }
            };
            return http.get('/', config).then(response => {
                commit('stallages', response.data);
            });
        },
        stallage({commit}, {id}) {
            return http.get('/' + id).then(response => {
                commit('stallage', response.data);
            });
        },
        nextStallages({state, commit}) {
            if(state.stallages.links && state.stallages.links.next) {
                return http.get(state.stallages.links.next).then(response => {
                    commit('nextStallages', response.data);
                });
            }
        },
        sendReceipt({commit, dispatch}, {id, email, f7}) {
            const data = JSON.stringify({email: email});
            f7.dialog.progress('Wysyłanie powiadomienia...');
            return new Promise((resolve, reject) => {
                http.post('/' + id + '/receipt', data).then(response => {
                    f7.dialog.close();
                    f7.dialog.progress('Wysłano', 100);
                    setTimeout(() => {
                        f7.dialog.close();
                        resolve();
                    }, 600);
                }).catch(error => {
                    var code = error.response.data.code;
                    if (code === 10301) error.message = 'Podany adres e-mail jest niepoprawny';
                    f7.dialog.close();
                    reject(error);
                });
            });
        },
        cancelStallage({commit, dispatch}, {id, reason, f7}) {
            const data = JSON.stringify({reason: reason});
            f7.dialog.progress('Odwoływanie...');
            return new Promise((resolve, reject) => {
                http.delete('/' + id + '', { data: data }).then(response => {
                    f7.dialog.close();
                    f7.dialog.progress('Odwołano', 100);
                    dispatch('stallage', {id: id}).then(() => {
                        f7.dialog.close();
                        resolve()
                    });
                }).catch(error => {
                    f7.dialog.close();
                    reject(error);
                });
            });
        },
        reports({commit}) {
            return http.get('/reports').then(response => {
                commit('reports', response.data);
            });
        },
        generateInvoice({commit, dispatch}, {id, number, fileId, bankAccount, issueDate, authorizedPerson, f7}) {
            const data = new FormData();
            data.append('number', number);
            data.append('fileId', fileId);
            data.append('bankAccount', bankAccount);
            data.append('issueDate', issueDate);
            data.append('authorizedPerson', authorizedPerson);

            f7.dialog.progress('Generowanie...');
            return new Promise((resolve, reject) => {
                http.post('/reports/' + id + '/generate_invoice', data).then(response => {
                    f7.dialog.close();
                    f7.dialog.progress('Dokument wygenerowany', 100);
                    dispatch('reports').then(() => {
                        f7.dialog.close();
                        resolve()
                    });
                }).catch(error => {
                    f7.dialog.close();
                    var code = error.response.data.code;
                    var details = error.response.data.details;
                    if (code === 10315) error.message = 'Nie można dołączać faktur do otwartych raportów';
                    if (code === 10316) error.message = 'Data wystawienia jest niepoprawna, dokument musi być wystawiony pomiędzy ' + details.possibleRange[0] + ' a ' + details.possibleRange[1];
                    reject(error);
                })
            });
        },
        sendInvoice({commit, dispatch}, {id, number, fileId, f7}) {
            const data = new FormData();
            data.append('number', number);
            data.append('fileId', fileId);

            let file = document.getElementById('file_' + fileId);
            data.append('file', file.files[0]);

            const options = {headers: {'Content-Type': 'multipart/form-data'}};

            f7.dialog.progress('Wysyłanie...');
            return new Promise((resolve, reject) => {
                http.post('/reports/' + id + '/invoice', data, options).then(response => {
                    f7.dialog.close();
                    f7.dialog.progress('Dokument wysłany', 100);
                    dispatch('reports').then(() => {
                        f7.dialog.close();
                        resolve()
                    });
                }).catch(error => {
                    f7.dialog.close();
                    reject(error);
                });
            });
        },
        afterLogin({dispatch}) {
            dispatch('zones');
            dispatch('countries');
            dispatch('stallages');
            dispatch('reports');
        },
        login({commit, dispatch}, {data, f7}) {
            http.post('/token', {
                login: data.login,
                password: data.password
            }).then(response => {
                try {
                    localStorage.setItem('token', response.data.token);
                    commit('login', response.data.token);
                    dispatch('afterLogin');
                } catch (e) {

                    f7.dialog.alert('Wystąpił nieznany błąd podczas logowania, skontaktuj się z administratorem systemu');

                }
            }).catch(e => {
                var message = e.response.data.error;
                var code = e.response.data.code;
                if (code === 10001) message = 'E-mail lub hasło jest niepoprawne';
                f7.dialog.alert(message);
            })
        },
        loginByPin({commit, dispatch}, {data, f7}) {
            return new Promise((resolve, reject) => {
                http.post('/token/pin', {
                    login: data.login,
                    pin: data.pin
                }).then(response => {
                    try {
                        localStorage.setItem('token', response.data.token);
                        commit('login', response.data.token);
                        dispatch('afterLogin');
                        resolve();
                    } catch (e) {
                        reject(e);
                        f7.dialog.alert('Wystąpił nieznany błąd podczas logowania, skontaktuj się z administratorem systemu');
                    }
                }).catch(e => {
                    var message = e.response.data.error;
                    var code = e.response.data.code;
                    if (code === 10004) message = 'Pin jest niepoprawny lub nieważny';
                    reject(message);
                })
            });

        },
        sendPin({commit, dispatch}, {data, f7}) {
            f7.dialog.preloader('Wysyłanie PINu...');
            http.post('/pin', {
                login: data.login
            }).then(response => {
                f7.dialog.close();
                commit('pin', 1);
            }).catch(e => {
                f7.dialog.close();
                var message = e.response.data.error;
                f7.dialog.alert(message);
            });
        },
        registerStallage({commit, dispatch}, {data, f7}) {

            f7.dialog.preloader(data.get('refusal')==='1' ? 'Rejestrowanie odmowy...' : 'Rejestrowanie opłaty...');

            const stallageId = data.get('id');
            const options = {headers: {'Content-Type': 'multipart/form-data'}};

            return new Promise((resolve, reject) => {
                http.post('/', data, options).then(response => {
                    dispatch('stallage', {id: stallageId}).then(() => {
                        f7.dialog.close();
                        resolve();
                    });
                }).catch(error => {
                    f7.dialog.close();
                    if(error.response && error.response.status===400) {
                        this.$f7.dialog.alert('Popraw formularz');
                        return;
                    }
                    f7.dialog.alert('Wystąpił nienany błąd systemu. Jeżeli problem będzie się ponawiał, skontaktuj się z administratorem.');
                    reject(error);
                })
            });
        },
        changePassword({commit, dispatch}, {data, f7}) {
            f7.dialog.preloader('Aktualizowanie hasła...');
            return new Promise((resolve, reject) => {
                http.patch('/user/password', data).then(response => {
                    f7.dialog.close();
                    f7.dialog.alert('Hasło zostało zmienione');
                    resolve();
                }).catch(e => {
                    f7.dialog.close();
                    var message = e.response.data.error;
                    var code = e.response.data.code;
                    if (code === 10003) message = 'Aktualne hasło jest niepoprawne';
                    if (code === 10004) message = 'Nowe hasło jest niepoprawne';
                    f7.dialog.alert(message);
                    reject(e);
                });
            });
        },
        logout({commit}) {
            commit('logout');
        }
    },
    mutations: {
        token (state, token) {
            state.user.token = token;
            state.user.payload = jwt.decode(token);
        },
        deleteToken (state) {
            state.user.token = null;
            state.user.payload = null;
        },
        zones (state, list) {
            state.zones = list.map(function (zone) {
                return zone;
            });
        },
        zone (state, zone) {
            state.zone = zone;
        },
        countries (state, list) {
            state.countries = list;
        },
        stallages (state, list) {
            state.stallages = list;
        },
        stallage (state, stallage) {
            let index = state.stallages.items.findIndex(item => item.id===stallage.id);
            if(!!state.stallages.items[index]) {
                state.stallages.items[index] = stallage;
                state.stallages.items = [...state.stallages.items]; // rebuild for vuex
            } else {
                state.stallages.items.unshift(stallage);
            }
        },
        nextStallages (state, list) {
            // push new items to the list
            list.items.forEach(function (item) {
                // prevent duplicates
                if(undefined===state.stallages.items.find(stallage => stallage.id === item.id)) {
                    state.stallages.items.push(item);
                }
            });
            state.stallages.links = list.links;
        },
        reports (state, list) {
            state.reports = list;
        },
        login (state, token) {
            http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            state.user.logged = true;
            state.user.token = token;
            state.user.payload = jwt.decode(token);
        },
        pin (state, value) {
            state.user.pin = value;
        },
        logout (state) {
            state.user.logged = false;
            state.user.token = null;
            state.user.payload = null;
            state.user.pin = false;
            localStorage.removeItem('token')
        },
        printer (state, value) {
            state.printer = value;
            localStorage.setItem('printer', value)
        },
    },
    strict: debug
});


store.commit('login', localStorage.getItem('token'));
store.commit('printer', localStorage.getItem('printer'));

http.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        store.dispatch('logout');
        return;
    }
    return Promise.reject(error);
});


export default store;
