<template>
    <div v-if="countries.length>0">
        <f7-block-header>{{headerLabel}}</f7-block-header>
        <f7-list inset>
            <f7-list-button :title="toggleTypeLabel" @click="toggleType()"></f7-list-button>
            <f7-list-input v-if="merchant.type==='person'"
                label="Imię i nazwisko"
                type="text"
                validate
                required
                pattern=".+ .+"
                placeholder="Imię i nazwisko"
                :value="merchant.name"
                @input="merchant.name = $event.target.value"
            ></f7-list-input>
            <f7-list-input v-if="merchant.type==='company'"
                label="Nazwa firmy"
                type="text"
                validate
                required
                pattern=".+"
                placeholder="Nazwa firmy"
                :value="merchant.name"
                @input="merchant.name = $event.target.value"
            ></f7-list-input>
            <f7-list-input v-if="false && merchant.type==='person'"
                label="PESEL"
                type="text"
                validate
                required
                pattern="[+0-9]{10}"
                placeholder="PESEL"
                :value="merchant.pesel"
                @input="merchant.pesel = $event.target.value"
            ></f7-list-input>
            <f7-list-input v-if="merchant.type==='company'"
               label="NIP"
               type="text"
               validate
               required
               pattern=".{5,20}"
               placeholder="NIP"
               :value="merchant.vatId"
               @input="merchant.vatId = $event.target.value"
            ></f7-list-input>
            <f7-list-input
                           label="Kod pocztowy"
                           type="text"
                           validate
                           :required="isRequired('address') || isRequired('postalCode')"
                           pattern=".{2,}"
                           placeholder="Kod pocztowy"
                           :value="merchant.address.postalCode"
                           @input="merchant.address.postalCode = $event.target.value"
            ></f7-list-input>
            <f7-list-input
                           label="Miasto"
                           type="text"
                           validate
                           required
                           pattern=".{2,}"
                           placeholder="Miasto"
                           :value="merchant.address.city"
                           @input="merchant.address.city = $event.target.value"
            ></f7-list-input>
            <f7-list-input
                           label="Ulica"
                           type="text"
                           validate
                           required
                           pattern=".{2,}"
                           placeholder="Ulica"
                           :value="merchant.address.street"
                           @input="merchant.address.street = $event.target.value"
            ></f7-list-input>
            <f7-list-item class="country-select"
                          label="Kraj"
                          placeholder="Kraj"
                          smart-select :smart-select-params="{
                       openIn: 'popup',
                       searchbar: true,
                       searchbarPlaceholder: 'Wybierz kraj',
                       popupCloseLinkText: 'Zamknij',
                       searchbarDisableText: 'Anuluj',
                       closeOnSelect: true
                   }">
                <select v-model="merchant.address.countryCode">
                    <optgroup v-for="group in countries" :label="group.name">
                        <option v-for="country in group.countries" :value="country.code">{{country.name}}</option>
                    </optgroup>
                </select>
                <div class="item-title item-label">Kraj</div>
            </f7-list-item>
        </f7-list>
    </div>
</template>
<script>
    export default {
        props: {
            tiers: Array,
            required: Array
        },
        data: function () {
            return {
                merchant: this.empty()
            };
        },
        computed: {
            headerLabel() {
               return this.merchant.type==='person' ? 'Dane osoby fizycznej' : 'Dane firmy';
            },
            toggleTypeLabel() {
                return this.merchant.type==='person' ? 'Zmień na firmę' : 'Zmień na osobę fizyczną';
            },
            countries: function () {
                return this.$store.getters.countries;
            },
        },
        methods: {
            isRequired(field) {
                return this.required.indexOf(field) >= 0;
            },
            toggleType() {
                this.merchant.type = this.merchant.type==='person' ? 'company' : 'person';
            },
            empty() {
                return {
                    type: 'person',
                    pesel: '',
                    vatId: '',
                    name: '',
                    phone: '',
                    email: '',
                    address: {
                        countryCode: 'PL',
                        postalCode: '',
                        city: '',
                        street: ''
                    }
                };
            }
        },
        watch: {
            merchant: {
                deep: true,
                handler: function () {
                    this.$emit('input', {merchant: this.merchant});
                }
            },
        },
        mounted() {
            this.$root.$on('stallageScanned', (payload) => {
                this.merchant = payload.merchant;
            });
        }
    };
</script>

<style>
.country-select .item-inner {
    display: block !important;
}

.country-select .smart-select .item-after {
    max-width: 100%;
    padding-left: 0;
    padding-top: 10px;
}

.country-select .item-inner:before {
    display: none !important;
    content: '';
}
</style>
