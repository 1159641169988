<template>
    <f7-page name="stallages" ptr @ptr:refresh="refresh">
        <f7-navbar>
            <logo title="Pokwitowania"></logo>

            <f7-subnavbar :inner="false">
                <f7-searchbar
                        placeholder="Szukaj"
                        disable-button-text="Anuluj"
                        search-container=".search-list"
                        search-in=".item-title, .item-subtitle, .item-text"
                        :disable-button="!$theme.aurora"
                        @searchbar:clear="resetList"
                ></f7-searchbar>
            </f7-subnavbar>
        </f7-navbar>

        <f7-list media-list class="search-list" virtual-list :virtual-list-params="{ items: [], searchAll, renderExternal, height: itemHeight}">
            <ul>
                <f7-list-item
                        v-for="stallage in vlData.items"
                        :key="stallage.id"
                        :link="'/stallages/'+stallage.id+'/'"
                        :animate="!masterDetailMode"
                        :title="title(stallage)|capitalize"
                        :style="`top: ${vlData.topPosition}px`"
                >
                    <div class="item-subtitle">{{stallage.recorded|moment('D.MM.YYYY HH:mm')}}
                        <small>w {{stallage.zone.name}}</small>
                    </div>
                    <div class="item-text">Należność: {{stallage.requested|price}}</div>
                    <div class="item-text" :style="'color: ' + infoLabel(stallage).color">{{ infoLabel(stallage).message }}</div>
                    <div class="item-text unresolved" v-if="stallage.leftToPay">Potwierdź pobranie</div>
                    <div class="item-text unresolved" v-if="stallage.leftToRefund">Potwierdź zwrot</div>
                    <!--div class="item-text" v-if="stallage.comments">Uwagi: <em>{{stallage.comments}}</em></div-->
                </f7-list-item>
            </ul>
        </f7-list>


    </f7-page>
</template>
<script>
    import logo from './components/logo.vue'

    export default {
        data: function () {
            return {
                logo: logo,
                virtualList: null,
                vlData: {
                    items: [],
                },
            };
        },
        computed: {
            itemHeight() {
                if(this.$f7.theme==='aurora') return 78;
                if(this.$f7.theme==='ios') return 107;
                if(this.$f7.theme==='md') return 113;
                return 100;
            },
            masterDetailMode() {
                return this.$f7.width >= 800;
            },
            stallages: function() {
                return this.$store.getters.stallages.items ? this.$store.getters.stallages.items : [];
            }
        },
        methods: {
            title(stallage) {
                return stallage.merchant.name;
            },
            infoLabel: function (stallage) {
                let color = null;
                let message = '';
                if(stallage.cancelled || stallage.refused) {
                    color = 'red';
                }

                if(stallage.cancelled) {
                    message = 'Odwołane';
                } else if(stallage.refused) {
                    message = 'Odmowa';
                } else {
                    let names = stallage.items.filter( ({name}) => !!name);
                    let namesPart = names.map( ({ name }) => name ).join(', ');

                    message = names.length > 0 ? namesPart : '';
                }

                return {
                    color: color,
                    message: message
                };
            },
            refresh(event, done) {
                this.$store.dispatch('stallages').then(() => done());
            },
            searchAll(query, items) {
                this.$store.dispatch('filteredStallages', {phrase: query});
                return [];
            },
            renderExternal(vl, vlData) {
                if(!!!this.virtualList) {
                    this.virtualList = vl;
                }

                if(vlData.toIndex>this.stallages.length-10) {
                    this.$store.dispatch('nextStallages')
                }

                this.vlData = vlData;
            },
            resetList() {
                this.$store.dispatch('stallages');
            }
        },
        watch: {
            stallages(newValue) {
                if(!!this.virtualList) {
                    this.virtualList.replaceAllItems(newValue);
                }
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        },
        components: {
            'logo': logo
        }
    };
</script>

<style>
    .item-text.unresolved {
        color: rgb(223, 0, 0);
        font-weight: bold;
    }
</style>
