<template>
    <form @submit="onSubmit">
        <f7-list inset>
            <li>
                <zone-select :always-visible="false" @input="form.zoneId = $event.zone"></zone-select>
            </li>

            <f7-list-input
                label="Data poboru"
                :value="formatDate(form.from)"
                disabled="disabled"
            ></f7-list-input>

            <!--f7-list-input
                label="Okres"
                type="datepicker"
                placeholder="Wybierz daty"
                :calendar-params="{dateFormat: 'dd.mm.yyyy', rangePicker: true, closeOnSelect: true, rangePickerMinDays: 2}"
                :value="dates"
                @calendar:change="dates = $event"
                error-message="Podaj okres"
                :error-message-force="datesNotValidAndFormTouched"
            ></f7-list-input-->
        </f7-list>


        <div v-if="tiers.length">
            <!--f7-block-title>Rodzaj sprzedaży</f7-block-title>
            <f7-list inset no-hairlines-md>
                <f7-list-item radio  v-for="s in saleTypes" :value="s" :key="s" :title="s" name="saleType" @click="currentSaleType=s" :checked="saleType===s"></f7-list-item>
            </f7-list-->

            <f7-block-header>Pozycje</f7-block-header>
            <f7-list inset v-for="tier in tiers" :key="tier.id + forceRefresh">
                <f7-list-item :title="tier.name"></f7-list-item>
                <f7-list-item :title="'Ilość ' + tier.unit ">
                    <f7-stepper :min=0 :max=10000 :value="form.items[tier.id]" @stepperChange="form.items[tier.id] = $event;"></f7-stepper>
                </f7-list-item>
            </f7-list>

            <merchant :required="required" @input="form.merchant = $event.merchant"></merchant>

            <f7-block-header>Komentarze</f7-block-header>
            <f7-list inset no-hairlines>
                <f7-list-button @click="takePicture()">Zrób zdjęcie</f7-list-button>
                <f7-list-item v-if="form.pictures.length>0">
                    <div style="display: inline">
                        <img v-for="(picture, i) in form.pictures" style="border-radius: 8px; margin-right: 10px;" :src="picture" @click="form.pictures.splice(i, 1)" width="98"/>
                    </div>
                </f7-list-item>
                <f7-list-input
                    type="textarea"
                    placeholder="Uwagi..."
                    :value="form.comments"
                    @input="form.comments = $event.target.value"
                ></f7-list-input>
            </f7-list>

            <f7-list inset>
                <f7-button raised large fill type="submit" @click="form.refusal = false; datePickerTouched = true">Zarejestruj opłatę</f7-button>
                <br>
                <f7-button raised large fill type="submit" @click="form.refusal = true; datePickerTouched = true" color="red">Zarejestruj odmowę</f7-button>
            </f7-list>
        </div>
        <f7-block v-else>
            Pobór opłaty w strefie <strong>{{zone.name}}</strong> jest niedostępny.
            W razie wątpliwości prosimy o kontakt z Urzędem.
        </f7-block>


    </form>
</template>

<script>
import uuid4 from 'uuid/v4'
import merchant from './merchant.vue';
import zoneSelect from './zone-select.vue';
import moment from 'moment';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';

export default {
    data() {
        return {
            options: {
                openIn: 'popup',
                searchbar: true, //accommodations.length>10,
                searchbarPlaceholder: 'Wybierz obiekt',
                popupCloseLinkText: 'Zamknij',
                searchbarDisableText: 'Anuluj',
                closeOnSelect: true,
                renderItem: this.renderItem
            },
            currentSaleType: null,
            dates: [],
            form: {
                id: uuid4(),
                paymentId: uuid4(),
                from: moment().format('YYYY-MM-DD'),
                to: moment().add(1, 'd').format('YYYY-MM-DD'),
                items: {},
                comments: null,
                refusal: false,
                pictures: []
            },
            forceRefresh: uuid4(),
            datePickerTouched: false
        }
    },
    computed: {
        datesNotValid() {
            return false; // remove to use date picker
            return ! (!!this.dates && this.dates.length===2);
        },
        saleType() {
            return this.currentSaleType ?? this.saleTypes[0];
        },
        datesNotValidAndFormTouched() {
            return false; // remove to use date picker
            return this.datesNotValid && this.datePickerTouched;
        },
        zone() {
            return this.$store.getters.zone;
        },
        zones() {
            return this.$store.getters.zones;
        },
        formItemQuantity() {
            return id => this.form.items[id] ?? 0;
        },
        saleTypes() {
            return this.zone.tiers.reduce((types, tier) => {
                if (!types.includes(tier.name)) {
                    types.push(tier.name);
                }
                return types;
            }, []);
        },
        saleTypeTiers() {
            return this.zone.tiers.filter((tier) => tier.name===this.saleType);
        },
        tiers() {
            return this.zone.tiers;
        },
        required() {
            return this.zone.required;
        }
    },
    watch: {
        dates(dates) {
            if(dates[0]) {
                this.form.from = moment(dates[0]).format('YYYY-MM-DD');
            }
            if(dates[1]) {
                this.form.to = moment(dates[1]).format('YYYY-MM-DD');
            }
        },
        zone(zone) {
            this.fillTiers(zone);
        }
    },
    methods: {
        formData() {
            const formData = new FormData();
            this.form.refusal = this.form.refusal ? '1' : '0';
            this.buildFormData(formData, this.form);
            return formData;
        },
        buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
                Object.keys(data).forEach(key => {
                    this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
                });
            } else {
                const value = data == null ? '' : data;
                formData.append(parentKey, value);
            }
        },
        formatDate(date) {
            return moment(date).format('D.MM.YYYY');
        },
        onSubmit(evt) {
            evt.preventDefault();

            this.form.zoneId = this.zone.id

            if(this.datesNotValid) {
                this.$f7.dialog.alert('Podaj datę poboru');
                return;
            }

            if(false===Object.values(this.form.items).some(value => value > 0)) {
                this.$f7.dialog.alert('Uzupełnij pozycje');
                return;
            }

            if(this.form.refusal && this.form.pictures.length===0) {
                this.$f7.dialog.alert('Wykonaj zdjęcia miejsca sprzedaży');
                return;
            }

            let vue = this;
            let stallageId = this.form.id;
            let redirect = this.form.refusal ? '/stallages/'+stallageId+'/' : '/stallages/'+stallageId+'/print';
            this.$store.dispatch('registerStallage', {
                data: this.formData(),
                f7: this.$f7
            }).then(function () {
                vue.$f7.popup.close();
                vue.$$('#view-stallages-tab').trigger('click');
                vue.$f7.views.get('#view-stallages').router.navigate(redirect);
                // reset form :/
                vue.$emit('registered', {uuid: vue.form.id});
            });

        },
        setDates(dates) {
            this.form.from = dates[0] ? moment(dates[0]).format('YYYY-MM-DD') : null;
            this.form.to = dates[1] ? moment(dates[1]).format('YYYY-MM-DD'): null;
        },
        fillTiers(zone) {
            this.form.items = {}; // clear items from previous zone
            zone.tiers.forEach(tier => this.form.items[tier.id] = 0);
        },
        async takePicture() {
            const image = await Camera.getPhoto({
                quality: 80, // save weight
                height: 1500, // save weight
                weight: 1500, // save weight
                allowEditing: false,
                resultType: CameraResultType.DataUrl,
                source: CameraSource.Camera
            });

            this.form.pictures.push(image.dataUrl);
        }
    },
    mounted() {
        this.$root.$on('stallageScanned', (payload) => {
            this.$store.commit('zone', this.$store.getters.zones.find(zone => zone.id === payload.zone.id));

            // watch(zone) is async so this must be in next tick
            this.$nextTick(() => {
                payload.items.forEach(item => this.form.items[item.rateId] = item.quantity);
                this.form.comments = payload.comments;
                this.forceRefresh = uuid4();
            });

        });

        this.fillTiers(this.zone);
    },
    components: {
        'merchant': merchant,
        'zone-select': zoneSelect,
    }
}
</script>

<style>

</style>
