<template>
    <f7-page name="reports" ptr @ptr:refresh="refresh">
        <f7-navbar>
            <logo title="Raporty"></logo>
        </f7-navbar>

        <f7-block v-if="reports.length===0">
            <p>Nie posiadasz jeszcze żadnych raportów. </p>
        </f7-block>

        <f7-block-title v-if="openReports.length">Otwarte raporty</f7-block-title>

        <f7-list media-list>
            <f7-list-item
                    v-for="report in openReports"
                    :key="report.id"
                    :title="report.name|capitalize"
                    :link="'/report/'+report.id+'/'"
                    :animate="!masterDetailMode"
                    :after="report.total|price"
      >
                <div class="item-subtitle">RP {{report.number}}</div>
                <div class="item-text" v-if="report.paid===false && report.closed">Oczekuje na wpłatę</div>
                <div class="item-text" v-if="report.paid===true && report.closed">Raport opłacony</div>
                <div class="item-text" v-if="!report.closed">Wpłat: {{report.paymentsCount}}, zwrotów: {{report.refundsCount}}</div>
            </f7-list-item>
        </f7-list>



        <f7-block-title v-if="closedReports.length">Zamknięte raporty</f7-block-title>

        <f7-list media-list>
            <f7-list-item
                    v-for="report in closedReports"
                    :key="report.id"
                    :title="report.name|capitalize"
                    :link="'/report/'+report.id+'/'"
                   :after="report.total|price"
      >
                <div class="item-subtitle">RP {{report.number}}</div>
                <div class="item-text" v-if="report.paid===false && report.closed">Oczekuje na wpłatę</div>
                <div class="item-text" v-if="report.paid===true && report.closed">Raport opłacony</div>
                <div class="item-text" v-if="!report.closed">Wpłat: {{report.paymentsCount}}, zwrotów: {{report.refundsCount}}</div>
            </f7-list-item>
        </f7-list>

    </f7-page>
</template>
<script>
    import logo from './components/logo.vue'

    export default {
        data: function () {
            return {
            };
        },
        computed: {
            masterDetailMode() {
                return this.$f7.width >= 800;
            },
            reports: function() {
                return this.$store.getters.reports.items ?? [];
            },
            openReports: function () {
                return this.reports.filter( ({closed}) => !!!closed);
            },
            closedReports: function () {
                return this.reports.filter( ({closed}) => !!closed);
            }
        },
        methods: {
            refresh(event, done) {
                this.$store.dispatch('reports').then(() => done());
            }
        },
        components: {
            'logo': logo
        }
    };
</script>
