<template>
    <f7-nav-title>
        <img v-if="!masterDetailMode" :src="logo" height="30" style="margin: 8px 0 4px 0">
        <span v-if="masterDetailMode">{{ title }}</span>
    </f7-nav-title>
</template>
<script>
    import logo from '../../assets/logo-dark.svg'

    export default {
        props: {
            title: String
        },
        data: function () {
            return {
                logo: logo
            };
        },
        computed: {
            masterDetailMode() {
                return this.$f7.width >= 800;
            }
        }
    };
</script>

<style></style>
