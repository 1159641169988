
import thermalPrinter from "thermal-printer-cordova-plugin/www/thermal-printer.js";
import innerPrinter from 'cordova-plugin-sunmi-inner-printer/www/innerprinter.js';
import moment from 'moment';


const INNER_PRINTER = 'inner';
const INNER_PRINTER_ADDRESS = '00:11:22:33:44:55';

const printer = {
    print: function (data, success, error, disconnect) {
        disconnect = disconnect ?? true;

        if(data.id===INNER_PRINTER) {
            data.id = INNER_PRINTER_ADDRESS;
        }

        if(data.id===INNER_PRINTER_ADDRESS)
        {
            data.charsetEncoding = {
                printerNbrCharactersPerLine: 32,
                printerWidthMM: 48.0,
                charsetId: 1,
                charsetName: 'GB18030'
            };
        }

        thermalPrinter.printFormattedText(
            data,
            (result) => success(result),
            result => {
                if(result.error==='Broken pipe' && disconnect)  {
                    thermalPrinter.disconnectPrinter(
                        data,
                        () => this.print(data, success, error, false),
                        error
                    );
                    return
                }
                error(result);
            });
    },

    testPrinter: async function (data, success, error, disconnect) {

        if(data.id===INNER_PRINTER)
        {
            const i = innerPrinter;
            await i.setAlignment(1);
            //await i.aty(WoyouConsts.ENABLE_BOLD, WoyouConsts.ENABLE),
            await i.printTextWithFont("Urząd Miasta Testowego\nTestowna 67\n12-234 Testowo\n\n", "", 22);
            await i.printTextWithFont("POKWITOWANIE POBORU\nOPŁATY TARGOWEJ\n", "", 30);
            await i.printTextWithFont("Nr 124/002/12/2024\n\n", "", 20, null);

            await i.setAlignment(0);
            await i.printTextWithFont("Wystawiono dla:\n"
                + "Warzywa i owoce Jan Nowak Sp. j.\n"
                + "ul. Marchewkowa 37\n"
                + "98-765 Ogórków\n"
                + "NIP: 1234567890\n"
                + "--------------------------------------",  "", 20, null);



            await i.setAlignment(0);
            await i.printTextWithFont(
                "17 x Przyczepa\n"
                + "20,00 zł / urządzenie        340,00 zł\n"
                + "--------------------------------------\n"
                + "Uwagi: dokonywano sprzedaży runa leśnego z kilku urządzeń\n"
                + "--------------------------------------\n",
            "", 20);

            await i.printTextWithFont("Razem:              ",  "", 23);
            await i.printTextWithFont("340,00 zł\n\n\n",  "", 30);

            await i.printTextWithFont(""
                + "Strefa:                      Strefa IV\n"
                + "Wystawionio:                12.12.2024\n"
                + "Inkasent:               Pieniążki S.A.\n\n\n",  "", 20, null);

            await i.setAlignment(1);
            await i.printQRCode("To nie jest kod QR", 7, 3);

            await i.printString("\n\n\n\n").then(success, error);
            return;
        }

        data.text = "[C]Urząd Miasta Testowego\n[C]Testowna 67\n[C]12-234 Testowo\n\n"
            + "[C]<font size='wide'>POKWITOWANIE POBORU\n[C]OPŁATY TARGOWEJ</font>\n"
            + "[L]\n"
            + "[L]<b>Wystawiono dla</b>[R]Nr 124/002/12/2024\n"
            + "[L]Warzywa i owoce Jan Nowak Sp. j.\n"
            + "[L]ul. Marchewkowa 37\n"
            + "[L]98-765 Ogórków\n"
            + "[L]NIP: 1234567890\n"
            + "[L]------------------------------------------\n"
            + "[L]<b>17 x Przyczepa</b>[R]340,00 zł\n"
            + "[L]   20,00 zł / urządzenie\n"
            + "[L]------------------------------------------\n"
            + "[L]<b>Razem</b>[R]<font size='big'>340,00 zł</font>\n"
            + "[L]\n"
            + "[L]\n"
            + "[L]Wystawionio: [R]12.12.2024\n"
            + "[L]Inkasent: [R]Pieniążki S.A.\n"
            + "[L]\n"
            + "[C]<qrcode size='25'>To nie jest kod QR</qrcode>\n"
            + "[L]\n"
            + "[L]\n"
            + "[L]\n";
        return this.print(data, success, error, disconnect)
    },


    printStallage: async function (printerId, stallage, success, error) {
        let inner = printerId===INNER_PRINTER;
        let perLine =  inner ? 38 : 42;

        let line = "[L]" + "-".repeat(perLine) + "\n";

        let title = "POKWITOWANIE POBORU\n[C]OPŁATY TARGOWEJ";
        let reason = "";

        if(stallage.cancelled) {
            title = "POKWITOWANIE\n[C]ANULOWANIA POBORU\n[C]OPŁATY TARGOWEJ";
            reason = "[L]<b>Powód:</b>\n[L]" + stallage.cancellationReason + "\n[L]\n[L]\n";
        }

        let comments = '';
        if(stallage.comments) {
            comments = "[L]Uwagi: " + stallage.comments + "\n" + line;
        }


        if(inner)
        {
            let line = "-".repeat(perLine) + "\n";


            const i = innerPrinter;
            await i.setAlignment(1);
            await i.printTextWithFont(stallage.receiptHeader +"\n\n", "", 22);
            await i.printTextWithFont(title.replace(/\[C\]/g, '') + "\n", "", 30);
            await i.printTextWithFont("Nr " + stallage.number + "\n\n", "", 20, null);

            if(stallage.cancelled) {
                reason = "Powód:\n" + stallage.cancellationReason + "\n\n";
            }

            if(stallage.comments) {
                comments = "Uwagi: " + stallage.comments + "\n" + line;
            }

            await i.setAlignment(0);
            await i.printTextWithFont(reason + "Wystawiono dla:\n"
                + stallage.merchant.name + "\n"
                + stallage.merchant.address.street + "\n"
                + stallage.merchant.address.postalCode + " " + stallage.merchant.address.city + "\n"
                + (stallage.merchant.type === 'company' ? "NIP: " + stallage.merchant.vatId + "\n" : '')
                + line
                + stallage.items.map(
                    (item) =>
                        `${item.quantity} x ${item.name}\n` +
                        `${this.formatPrice(item.price)} / ${item.unit}` +
                        this.formatPrice(item.value).padStart(38 - `${this.formatPrice(item.price)} / ${item.unit}`.length) + "\n"
                ).join('')
                + line
                + comments,
                "", 20);

            await i.printTextWithFont("Razem:",  "", 23);
            await i.printTextWithFont(this.formatPrice(stallage.charged).padStart(20) + "\n\n\n",  "", 30);

            await i.printTextWithFont(""
                + "Strefa:" + stallage.zone.name.padStart(38-7) + "\n"
                + "Wystawionio:" + moment(stallage.from).format('D.MM.YYYY').padStart(38-12) + "\n"
                + "Inkasent:" + stallage.collector.name.padStart(38-9) + "\n\n\n",  "", 20, null);

            await i.setAlignment(1);
            await i.printQRCode(stallage.id, 6, 1);

            await i.printString("\n\n\n\n").then(success, error);
            return;
        }


        let data = {
            type: 'bluetooth',
            printerWidthMM: 50.0,
            printerNbrCharactersPerLine: perLine,
            id: printerId,
            charsetEncoding: {
                charsetId: 18,
                charsetName: 'IBM852'
            },
        };

        let that = this


        let header = stallage.receiptHeader.split("\n")
            .reduce((header, line) => header + "\n[C]" + line.trim(), "");
        this.print({
                ...data,
                text: header + "\n"
                    + "[L]\n"
                    + "[C]<font size='wide'>" + title + "</font>\n"
                    + "[L]\n"
                    + reason
                    + "[L]<b>Wystawiono dla</b>[R]Nr " + stallage.number + "\n"
                    + "[L]" + stallage.merchant.name + "\n"
                    + "[L]" + stallage.merchant.address.street + "\n"
                    + "[L]" + stallage.merchant.address.postalCode + " " + stallage.merchant.address.city + "\n"
                    + (stallage.merchant.type === 'company' ? "[L]NIP: " + stallage.merchant.vatId + "\n" : '')
                    + line
                    + stallage.items.map(
                        (item) =>
                            `[L]<b>${that.formatQuantity(item.quantity)} x ${item.name}</b>[R]${that.formatPrice(item.value)}\n` +
                            `[L]   ${that.formatPrice(item.price)} / ${item.unit}\n`
                    ).join('')
                    + "[L]" + "-".repeat(perLine) + "\n"
                    + comments
                    + "[L]<b>Razem</b>[R]<font size='big'>" + this.formatPrice(stallage.charged) + "</font>\n"
                    + "[L]\n"
                    + "[L]\n"
                    + "[L]Strefa: [R]" + stallage.zone.name + "\n"
                    + "[L]Wystawionio: [R]" + moment(stallage.from).format('D.MM.YYYY') + "\n"
                    + "[L]Inkasent: [R]" + stallage.collector.name + "\n"
                    //+ "[L]Użytkownik: [R]Marian Nowakowski\n"
                    + "[L]\n"
                    + "[C]<qrcode size='25'>" + stallage.id + "</qrcode>\n"
                    + "[L]\n"
                    + "[L]\n"
                    + "[L]\n"

            },
            success,
            error
        );
    },


    printReport: async function (printerId, daySummary, report, success, error) {
        let inner = printerId===INNER_PRINTER;
        let perLine =  inner ? 38 : 42;

        let line = "[L]" + "-".repeat(perLine) + "\n";

        let that = this

        if(inner)
        {
            let line = "-".repeat(perLine) + "\n";


            const i = innerPrinter;
            await i.setAlignment(1);
            await i.printTextWithFont("RAPORT DZIENNY\nOPŁATY TARGOWEJ\n", "", 30);
            await i.printTextWithFont("Za dzień " + moment(daySummary.day).format('D.MM.YYYY') + "\n\n", "", 20, null);


            await i.setAlignment(0);
            await i.printTextWithFont( "Zestawienie osób:\n"
                + line
                + daySummary.users.map(
                    user => user.name + that.formatPrice(user.amount).padStart(38-user.name.length) + "\n"
                ).join('')
                + line, "", 20);

            await i.printTextWithFont("Razem:",  "", 23);
            await i.printTextWithFont(this.formatPrice(daySummary.users.reduce( (total, user) =>  total + user.amount, 0.0 )).padStart(20) + "\n\n\n",  "", 30);

            await i.printTextWithFont(""
                + "Raport:" + report.number.padStart(38-7) + "\n"
                + "Data wydruku:" + moment().format('D.MM.YYYY').padStart(38-13) + "\n"
                + "Inkasent:" + report.collector.name.padStart(38-9) + "\n\n\n\n",  "", 20, null)
                .then(success, error);

            return;
        }


        let data = {
            type: 'bluetooth',
            printerWidthMM: 50.0,
            printerNbrCharactersPerLine: perLine,
            id: printerId,
        };


        this.print({
            ...data,
            text: "\n"
                + "[C]<font size='wide'>RAPORT DZIENNY\n[C]OPŁATY TARGOWEJ</font>\n"
                + "[L]<b>Za dzień:</b>[R]" + moment(daySummary.day).format('D.MM.YYYY') + "\n\n"
                + "[L]Zestawienie osób:\n"
                + line
                + daySummary.users.map(
                    user => `[L]<b>${user.name}</b>[R]${that.formatPrice(user.amount)}\n`
                ).join('')
                + line
                + "[L]<b>Razem</b>[R]<font size='big'>" + this.formatPrice(daySummary.users.reduce( (total, user) =>  total + user.amount, 0.0 )) + "</font>\n"
                + "[L]\n"
                + "[L]\n"
                + "[L]Raport: [R]" + report.number + "\n"
                + "[L]Inkasent: [R]" + report.collector.name + "\n"
                + "[L]Data wydruku: [R]" + moment().format('D.MM.YYYY') + "\n"
                + "[L]\n"
                + "[L]\n"
                + "[L]\n"
            },
            success,
            error
        );
    },

    formatQuantity: function(number) {
        const numStr = number.toString();
        const padding = Math.max(2 - numStr.length, 0);
        return ' '.repeat(padding) + numStr;
    },

    formatPrice: function(number) {
        return number.toFixed(2).toString().replace('.', ',') + ' zł';
    },

    requestPermissions: function (success, error) {
        thermalPrinter.requestBTPermissions({type: 'bluetooth'}, success, error);
    },
    listPrinters: function (success, error) {
        thermalPrinter.listPrinters({type: 'bluetooth'}, result => {
            if(innerPrinter.hasPrinter()) {
                result.unshift({name: 'SunmiPrinter', address: INNER_PRINTER});
            }
            return success(result);
        }, error);
    }
};

export default printer;
