// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from '../../build/framework7.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from '../../build/framework7-vue.esm.bundle.js';

import Framework7Keypad from 'framework7-plugin-keypad';
import 'framework7-plugin-keypad/dist/framework7-keypad.css';


// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';

// Import App Component
import App from '../components/app.vue';

import store from '../store'
import VueMoment from 'vue-moment'

import moment from 'moment'
moment.locale('pl');



Vue.use(VueMoment, {moment});

Vue.filter('capitalize', function (value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1)
});

Vue.filter('price', function (number) {
  return number.toFixed(2).toString().replace('.', ',') + ' zł';
});

Vue.filter('int', function (number) {
  return number.toString();
});

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);
Framework7.use(Framework7Keypad);

// Init App
new Vue({
  el: '#app',
  store,
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App
  },
});

