<template>
    <f7-page name="home" ptr @ptr:refresh="refresh">
        <f7-navbar>
            <logo :title="viewTitle"></logo>
        </f7-navbar>

        <register-form v-if="zone" :key="registerFormKey" @registered="registerFormKey += 1"></register-form>

        <f7-block v-else>
            Pobór opłaty jest niedostępny.
            W razie wątpliwości prosimy o kontakt z Urzędem.
        </f7-block>
    </f7-page>
</template>

<script>
    import logo from './components/logo.vue'
    import registerForm from './components/register-form.vue';

    export default {
        data() {
            return {
                logo: logo,
                registerFormKey: 1
            }
        },
        computed: {
            masterDetailMode() {
                return this.$f7.width >= 800;
            },
            zones: function () {
                return this.$store.getters.zones;
            },
            zone: function () {
                return this.$store.getters.zone;
            },
            viewTitle: function ()
            {
                return 'Rejestrowanie wpłaty';
            }
        },

        methods: {
            refresh(event, done) {
                this.$store.dispatch('zones').then(() => done());
            }
        },
        components: {
            'register-form': registerForm,
            'logo': logo
        }
    }
</script>

<style>

</style>
