<template>
    <f7-page name="stallage">
        <f7-navbar :back-link="masterDetailMode ? null : 'Powrót'">
            <f7-nav-title>{{stallage.number}}</f7-nav-title>
        </f7-navbar>


        <f7-block v-if="topWarning" style="color: red; text-align: center;">
            {{topWarning}}
        </f7-block>

        <f7-list inline-labels inset>
            <f7-list-button @click="fillRegisterForm()" title="Rejestruj ponownie"></f7-list-button>
        </f7-list>


        <f7-block-header>Rozliczenie</f7-block-header>
        <f7-list inline-labels inset>
            <f7-list-item v-if="stallage.requested" title="Należność" :after="stallage.requested|price"></f7-list-item>
            <f7-list-item title="Pobrano" :after="stallage.charged|price"></f7-list-item>
            <f7-list-button @click="chargePayment($event, stallage.leftToPay)" v-if="stallage.leftToPay" color="red" :class="paymentButtonClass">
                <div class="item-title">Potwierdź pobranie {{stallage.leftToPay|price}}</div>
            </f7-list-button>
            <f7-list-button @click="refundPayment($event, stallage.leftToRefund)" v-if="stallage.leftToRefund" color="green" :class="paymentButtonClass">
                <div class="item-title">Potwierdź zwrot {{stallage.leftToRefund|price}}</div>
            </f7-list-button>
            <f7-list-button v-if="stallage.hasReceipt" @click="print()" title="Drukuj potwierdzenie"></f7-list-button>
            <f7-list-button v-if="stallage.hasReceipt && allowReceiptDownload" :external="true" :href="receiptUrl" title="Pobierz potwierdzenie"></f7-list-button>
            <f7-list-button v-if="stallage.hasReceipt && !sendReceiptPopupOpened" @click="sendReceiptPopupOpened = true" :title="sendReceiptLabel"></f7-list-button>
        </f7-list>

        <form @submit="sendReceipt" v-if="sendReceiptPopupOpened" @popup:closed="sendReceiptPopupOpened = false">
            <f7-block-header>Wysyłanie potwierdzenia na e-mail</f7-block-header>
            <f7-list inline-labels media-list inset>
                <f7-list-input
                    label="E-mail"
                    type="email"
                    validate
                    required
                    placeholder="E-mail"
                    :value="sendReceiptEmail"
                    @input="sendReceiptEmail = $event.target.value"
                ></f7-list-input>
                <f7-list-item class="sendReceipt">
                    <f7-button large type="submit">Wyślij potwierdzenie</f7-button>
                </f7-list-item>
            </f7-list>
        </form>

        <div v-if="stallage.items">
            <f7-block-header>Pozycje</f7-block-header>
            <f7-list accordion-list inset>
                <f7-list-item v-for="(item, no) in stallage.items" :key="no">
                    <div class="item-title">
                        {{item.name}}
                        <div class="item-footer">{{ item.quantity }} * {{ item.price|price }} / {{ item.unit }}</div>
                    </div>
                    <div class="item-after"><span>{{ item.value|price }}</span></div>
                </f7-list-item>
            </f7-list>
        </div>




        <f7-block-header>Sprzedawca</f7-block-header>
        <f7-list inset>
            <f7-list-item :header="stallage.merchant.type==='company' ? 'Nazwa firmy' : 'Imię i Nazwisko'" :title="stallage.merchant.name"></f7-list-item>
            <f7-list-item v-if="stallage.merchant.type==='company'" header="NIP" :title="stallage.merchant.vatId"></f7-list-item>
            <f7-list-item header="Adres" :title="stallage.merchant.address.street + ', ' + stallage.merchant.address.postalCode + ' ' + stallage.merchant.address.city + ', ' + stallage.merchant.address.countryCode "></f7-list-item>
        </f7-list>


        <f7-block-header>Strefa</f7-block-header>
        <f7-list simple-list inset>
            <f7-list-item :title="stallage.zone.name"></f7-list-item>
        </f7-list>

        <f7-block-header v-if="!!stallage.comments">Komentarze</f7-block-header>
        <f7-block inset v-if="!!stallage.comments" strong><p>{{stallage.comments}}</p></f7-block>


        <f7-list inset v-if="!stallage.cancelled && !stallage.refused">
            <f7-list-button @click="cancelPrompt" :title="cancelStallageButton" color="red"></f7-list-button>
        </f7-list>

        <br>
        <br>
    </f7-page>
</template>
<style>

    .sendReceipt .item-inner {
        padding-top: 0;
        padding-bottom: 0;
    }

</style>
<script>
import uuid4 from 'uuid/v4';
import { Capacitor } from '@capacitor/core';
import printer from '../js/printer';


export default {
        data: function () {
            return {
                sendReceiptPopupOpened: false,
                sendReceiptEmail: null,
                sendReceiptPhone: null,
                paymentsDisabled: false,
                paymentId: uuid4(),
                refundId: uuid4(),
            };
        },
        computed: {
            masterDetailMode() {
                return this.$f7.width >= 800;
            },
            stallage() {
                let id = this.$f7route.params.id;
                return this.$store.getters.stallage(id);
            },
            receiptUrl: function() {
                return this.$store.state.apiUri + this.stallage.id + '.pdf?token=' + this.$store.state.user.token;
            },
            sendReceiptLabel: function () {
                return 'Wyślij potwierdzenie';
            },
            allowReceiptDownload: function () {
                return !this.native;
            },
            paymentButtonClass() {
                return this.paymentsDisabled ? 'disabled' : '';
            },
            cancelStallageButton() {
                return 'Odwołaj wpłatę';
            },
            topWarning() {
                if(this.stallage.cancelled) {
                    return 'Uwaga! To pokwitowanie jest odwołane.';
                }

                if(this.stallage.refused) {
                    return 'Uwaga! To meldunek odmowy opłaty.';
                }

                return false;
            },
            native: function () {
                return Capacitor.getPlatform()!=='web';
            }
        },
        methods: {
            fillRegisterForm() {
                let dialog = this.$f7.dialog;
                this.$$('#view-home-tab').trigger('click');
                dialog.progress('Dane uzupełnione', 100);
                this.$root.$emit('stallageScanned', this.stallage);
                setTimeout(() => dialog.close(), 300);
            },
            print() {
                let dialog = this.$f7.dialog;

                if(false===this.native) {
                    dialog.progress('Pomijam drukowanie...', 100);
                    setTimeout(() => dialog.close(), 500);
                    return;
                }

                dialog.preloader('Drukowanie...');

                printer.printStallage(
                    this.$store.getters.printer,
                    this.stallage,
                    () => dialog.close(),
                    error => {
                        dialog.close();
                        if(error.error==='Unable to connect to bluetooth device.')  {
                            dialog.alert('Nie można połączyć się z drukarką, sprawdź czy jest włączona i połączona z telefonem w ustawieniach Bluetooth');
                        }
                        if(error.error==='Broken pipe')  {
                            dialog.alert('Wystąpił błąd komunikacji z drukarką. Upewnij się, że durkarka jest wspierana.');
                        }
                        console.error('Printing error', JSON.stringify(error));
                });
            },
            formatPrice(number) {
                return number.toFixed(2).toString().replace('.', ',') + ' zł';
            },
            chargePayment(event, amount) {
                event.preventDefault();
                if(this.paymentsDisabled) {
                    return;
                }
                this.paymentsDisabled = true;
                this.$store.dispatch('chargePayment', {id: this.stallage.id, amount: amount, paymentId: this.paymentId, f7: this.$f7})
                    .finally( () => {
                        this.paymentsDisabled = false;
                        this.paymentId = uuid4();
                    });
            },
            sendReceipt(event) {
                event.preventDefault();
                let vue = this;
                this.$store.dispatch('sendReceipt', {id: this.stallage.id, email: this.sendReceiptEmail, f7: this.$f7})
                    .then( () => vue.sendReceiptPopupOpened = false )
                    .catch( (e) => vue.$f7.dialog.alert( e.message ?? 'Wystąpił problem z wysyłką powiadomienia, spróbuj ponownie.') );
            },
            cancelPrompt() {
              const message = 'Podaj powód odwołania';
              this.$f7.dialog.prompt(message, 'Uwaga!', this.cancel);
            },
            cancel(reason) {
                if(reason==='') {
                    this.cancelPrompt();
                    return;
                }
                this.$store.dispatch('cancelStallage', {
                    id: this.stallage.id,
                    reason: reason,
                    f7: this.$f7
                }).catch( (e) => console.log('fail', e));
            },
        },
        mounted() {
            this.sendReceiptEmail = this.stallage.items.reduce(function (carry, guest) {
                if (guest.email) {
                    carry = guest.email;
                }
                return carry;
            }, '');

            this.sendReceiptPhone = this.stallage.items.reduce(function (carry, guest) {
                if (guest.email) {
                    carry = guest.phone;
                }
                return carry;
            }, '');

            if (this.$f7route.params.action && this.$f7route.params.action === 'print') {
                this.print();
            }
        }


    };
</script>
