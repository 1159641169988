<template>
    <f7-app color="blue" :params="f7params">
        <!-- Status bar overlay for fullscreen mode-->
        <f7-statusbar :class="userLogged ? 'statusbar-dark' : 'statusbar-light'"></f7-statusbar>


        <!-- Views/Tabs container -->
        <f7-views tabs class="safe-area">
            <!-- Tabbar for switching views-tabs -->
            <f7-toolbar tabbar labels bottom v-if="toolbar">
                <f7-link v-if="masterDetailMode"
                         tab-link
                         popup-open="#register-popup"
                         icon-ios="f7:tickets"
                         icon-aurora="f7:tickets"
                         icon-md="f7:tickets"
                         :text="homeViewLabel">
                </f7-link>
                <f7-link v-if="!masterDetailMode"
                         id="view-home-tab"
                         tab-link="#view-home"
                         tab-link-active
                         icon-ios="f7:tickets"
                         icon-aurora="f7:tickets"
                         icon-md="f7:tickets"
                         :text="homeViewLabel">
                </f7-link>

                <f7-link v-if="native" tab-link @click="scan" icon-ios="f7:qrcode_viewfinder" icon-aurora="f7:qrcode_viewfinder" icon-md="f7:qrcode_viewfinder" text="Skanuj"></f7-link>

                <f7-link id="view-stallages-tab" tab-link="#view-stallages" :tab-link-active="masterDetailMode">
                    <f7-icon ios="f7:list" aurora="f7:list" md="material:list">
                        <f7-badge color="red" v-if="unpaidStallages">{{unpaidStallages}}</f7-badge>
                    </f7-icon>
                    <span class="tabbar-label">Pokwitowania</span>
                </f7-link>
                <f7-link tab-link="#view-reports" icon-ios="f7:document_text" icon-aurora="f7:document_text" icon-md="f7:document_text" text="Raporty"></f7-link>
                <f7-link tab-link popup-open="#user-popup">
                    <f7-icon ios="f7:settings" aurora="f7:settings" md="f7:settings">
                        <f7-badge color="red" v-if="printerNoPermissions">!</f7-badge>
                        <f7-badge color="orange" v-if="printers.length===0">0</f7-badge>
                    </f7-icon>
                    <span class="tabbar-label">Ustawienia</span>
                </f7-link>
            </f7-toolbar>

            <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
            <f7-view id="view-home" name="home" tab :tab-active="!masterDetailMode"  url="/"></f7-view>
            <f7-view id="view-stallages" name="stallages" tab  url="/stallages/"></f7-view>
            <f7-view id="view-reports" name="reports" tab url="/reports/"></f7-view>


        </f7-views>


        <!-- Popup -->
        <f7-popup id="register-popup">
            <f7-view url="/"></f7-view>
        </f7-popup>

        <f7-popup id="user-popup" v-if="payload">
            <f7-view>
                <f7-page>
                    <f7-navbar title="Ustawienia">
                        <f7-nav-right>
                            <f7-link popup-close>Zamknij</f7-link>
                        </f7-nav-right>
                    </f7-navbar>

                    <f7-block-header>Drukarki</f7-block-header>
                    <f7-list v-if="printers.length>0" inset strong-ios outline-ios dividers-ios>
                        <f7-list-item
                                v-for="(item, no) in printers"
                                :key="no"
                                :value="item.address"
                                radio
                                checkbox-icon="end"
                                :title="item.name"
                                @click="setPrinter($event, item.address)"
                                :checked="item.address===printer"
                        />
                        <f7-list-button @click="openBluetoothSettings()" title="Otwórz ustawienia Bluetooth"></f7-list-button>
                        <f7-list-button @click="refreshPrinterSetup()" title="Odśwież listę urządzeń"></f7-list-button>
                    </f7-list>

                    <f7-list inset strong v-if="printers.length===0 && native">
                        <f7-list-item>
                            Nie wykryto żadnych drukarek. Musisz najpierw sparować drukarkę Bluetooth z telefonem.
                        </f7-list-item>
                        <f7-list-button @click="openBluetoothSettings()" title="Otwórz ustawienia Bluetooth"></f7-list-button>
                        <f7-list-button @click="refreshPrinterSetup()" title="Odśwież listę urządzeń"></f7-list-button>
                    </f7-list>

                    <f7-list inset strong v-if="printers.length===0 && !native">
                        <f7-list-item>
                            Drukarki nie są obsługiwane w wersji przeglądarkowej.
                        </f7-list-item>
                    </f7-list>



                    <form @submit="changePassword">
                        <f7-block-header>Zmiana hasła</f7-block-header>
                        <f7-list inset>
                            <input v-show="false" type="text" name="username" :value="payload.login" autocomplete="username">
                            <f7-list-input
                                    v-if="requireOldPassword"
                                    label="Aktualne hasło"
                                    type="password"
                                    autocomplete="current-password"
                                    placeholder="Aktualne hasło"
                                    :value="newPassword.currentPassword"
                                    @input="newPassword.currentPassword = $event.target.value"
                                    required
                            ></f7-list-input>
                            <f7-list-input
                                    label="Nowe hasło"
                                    type="password"
                                    autocomplete="new-password"
                                    placeholder="Nowe hasło"
                                    :value="newPassword.newPassword"
                                    @input="newPassword.newPassword = $event.target.value"
                                    :error-message="newPasswordErrorMessage"
                                    :error-message-force="newPasswordValidator"
                                    required
                            ></f7-list-input>
                            <f7-list-input
                                    label="Powtórz nowe hasło"
                                    type="password"
                                    autocomplete="new-password"
                                    placeholder="Powtórz nowe hasło"
                                    :value="newPassword.newPasswordConfirmation"
                                    @input="newPassword.newPasswordConfirmation = $event.target.value"
                                    :error-message="newPasswordConfirmationErrorMessage"
                                    :error-message-force="newPasswordConfirmationValidator"
                                    required
                            ></f7-list-input>
                            <f7-list-button @click="changePassword" title="Zmień hasło"></f7-list-button>
                        </f7-list>
                    </form>

                    <f7-list inset no-hairlines-md>
                        <f7-list-button title="Wyloguj się" @click="logout"></f7-list-button>
                    </f7-list>
                </f7-page>
            </f7-view>
        </f7-popup>

        <f7-login-screen id="my-login-screen" :opened="!userLogged">
            <f7-view>
                <f7-page login-screen>
                    <f7-login-screen-title><img :src="logo" height="35"></f7-login-screen-title>
                    <form @submit="login" v-if="!loginScreen.resetPassword">
                        <f7-list>
                            <f7-list-input
                                    label="E-mail"
                                    type="email"
                                    validate
                                    required
                                    name="login"
                                    autocomplete="username"
                                    placeholder="Podaj e-mail"
                                    :value="user.login"
                                    @input="user.login = $event.target.value"
                            ></f7-list-input>
                            <f7-list-input
                                    label="Hasło"
                                    type="password"
                                    autocomplete="current-password"
                                    name="password"
                                    validate
                                    required
                                    placeholder="Podaj hasło"
                                    :value="user.password"
                                    @input="user.password = $event.target.value"
                            ></f7-list-input>
                        </f7-list>
                        <f7-list inset>
                            <f7-button type="submit">Zaloguj się</f7-button>
                            <f7-button @click="loginScreen.resetPassword = 1">Nie pamiętam hasła</f7-button>
                        </f7-list>
                    </form>

                    <form @submit="sendPin" v-if="loginScreen.resetPassword && !showPinPad">
                        <f7-list>
                            <f7-list-input
                                    label="E-mail"
                                    type="email"
                                    name="login"
                                    placeholder="Podaj e-mail"
                                    :value="user.login"
                                    @input="user.login = $event.target.value"
                            ></f7-list-input>
                        </f7-list>
                        <f7-list inset>
                            <f7-button type="submit">Wyślij PIN</f7-button>
                            <f7-button @click="loginScreen.resetPassword = 0">Powrót</f7-button>
                        </f7-list>
                    </form>

                    <form v-if="loginScreen.resetPassword && showPinPad">
                        <h3 style="text-align: center">{{ pinAlert }}</h3>
                        <f7-list>
                            <f7-list-input
                                    :input-id="elementId('pin-keypad')"
                                    class="pin-keypad"
                                    name="login"
                                    type="numpad"
                                    placeholder="_____"
                            ></f7-list-input>
                            <f7-list inset>
                                <f7-button @click="logout()">Powrót</f7-button>
                            </f7-list>
                        </f7-list>
                    </form>
                </f7-page>
            </f7-view>
        </f7-login-screen>
    </f7-app>
</template>
<script>

    import routes from '../js/routes.js';
    import logo from '../assets/logo-light.png'
    import Framework7 from '../../build/framework7.esm.bundle.js';
    import { Keyboard } from '@capacitor/keyboard';
    import { NativeSettings, AndroidSettings } from 'capacitor-native-settings';
    import { QrScanner } from '@diningcity/capacitor-qr-scanner';
    import { Capacitor } from '@capacitor/core';
    import printer from '../js/printer';




    export default {
        data() {
            let theme = Framework7.device.desktop ? 'aurora' : 'ios';  // na pewno ios na andka? :D
            return {
                id: 1,
                logo: logo,
                pinAlert: 'Podaj PIN otrzymany na skrzynkę e-mail',
                user: {
                    pin: '_____',
                    login: null,
                    password: null,
                },
                newPassword: {
                    currentPassword: '',
                    newPassword: '',
                    newPasswordConfirmation: ''
                },
                loginScreen: {
                    resetPassword: false
                },
                masterDetailMode: false,
                // Framework7 Parameters
                f7params: {
                    name: 'Targomat',
                    theme: theme,
                    language: 'pl',
                    calendar: {
                        monthNames: [
                            'Styczeń',
                            'Luty',
                            'Marzec',
                            'Kwiecień',
                            'Maj',
                            'Czerwiec',
                            'Lipiec',
                            'Sierpień',
                            'Wrzesień',
                            'Październik',
                            'Listopad',
                            'Grudzień'
                        ],
                        dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
                        dayNamesShort: ['Nie', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'So']
                    },
                    dialog: {
                        buttonCancel: 'Anuluj',

                    },
                    // App routes
                    routes: routes,
                    view: {
                        //pushState: true,
                        masterDetailBreakpoint: 800,
                    },
                    // Register service worker
                    serviceWorker: {
                        path: '/service-worker.js',
                    }
                },
                printerNoPermissions: true,
                printers: [], // .name + .address
                toolbar: true
            }
        },
        computed: {
            userLogged() {
                return this.$store.getters.userLogged;
            },
            showPinPad() {
                if(this.$store.getters.pinCreated) {
                    this.$nextTick(function () {
                        this.createPinKeypad();
                    });
                }
                return this.$store.getters.pinCreated;
            },
            token() {
                return this.$store.state.user.token;
            },
            payload() {
                return this.$store.state.user.payload;
            },
            stallages() {
                return this.$store.state.stallages.items ? this.$store.state.stallages.items : [];
            },
            unpaidStallages() {
                return this.stallages.filter( ({leftToPay}) => leftToPay!==0).length;
            },
            requireOldPassword() {
                return !this.payload.noValidationPasswordChange;
            },
            newPasswordErrorMessage() {
                if(this.newPassword.newPassword==='') return '';
                return this.newPassword.newPassword>=6 ? 'Hasło musi zawierać jedną liczbę i znak' : 'Hasło musi składać się z minimum 6 znaków';
            },
            newPasswordValidator() {
                let newPassword = this.newPassword.newPassword;
                return !(newPassword.length>=6 && newPassword.match(/\d/) && newPassword.match(/[a-zA-Z]/));
            },
            newPasswordConfirmationErrorMessage() {
                return this.newPasswordConfirmationValidator ? 'Powtórzone hasło nie jest identyczne': '';
            },
            newPasswordConfirmationValidator() {
                return this.newPassword.newPasswordConfirmation !== this.newPassword.newPassword;
            },
            zone: function () {
                return this.$store.getters.zone;
            },
            printer: function () {
                return this.$store.getters.printer;
            },
            homeViewLabel: function ()
            {
                if(!this.zone || this.zone.mode!=='front_desk_app') {
                    return 'Pobierz';
                }

                return 'Rejestruj';
            },
            native: function () {
                return Capacitor.getPlatform()!=='web';
            },
        },
        methods: {
            login(event) {
                event.preventDefault();
                this.$store.dispatch('login', {data: this.user, f7: this.$f7});
            },
            sendPin(event) {
                event.preventDefault();
                this.$store.dispatch('sendPin', {data: this.user, f7: this.$f7});
            },
            logout() {
                this.$store.dispatch('logout');
            },
            changePassword() {

                if(this.newPasswordValidator || this.newPasswordConfirmationValidator) return;

                this.$store.dispatch('changePassword', {data: this.newPassword, f7: this.$f7}).then(() => {
                    this.newPassword.currentPassword = '';
                    this.newPassword.newPassword = '';
                    this.newPassword.newPasswordConfirmation = '';
                });
            },
            createPinKeypad() {
                let keypad = this.$f7.keypad.create({
                    inputEl: '#' + this.elementId('pin-keypad'),
                    toolbarCloseText: 'Gotowe',
                    valueMaxLength: 5,
                    value: '',
                    dotButton: false,
                    routableModals: false,
                    formatValue: function (value) {
                        value = value.toString();
                        return value + ('_____').substring(0, 5 - value.length);
                    },
                    on: {change: this.loginByPin },
                });
                keypad.open();

            },
            loginByPin(keypad, value) {
                console.log(value);
                let $f7 = this.$f7;
                value = value.toString();
                this.user.pin = value;
                if (value.length===5) {
                    keypad.close();
                    this.$store.dispatch('loginByPin', {data: this.user, f7: this.$f7})
                    .then( () => {
                        console.log('ok');

                        this.loginScreen.resetPassword = false;
                        $f7.popup.open('#user-popup');
                    })
                    .catch( (message) => {
                        console.error(message);
                        keypad.close();
                        keypad.setValue('');
                        keypad.open();
                        this.user.pin = '';
                        this.pinAlert = message;
                    });
                }
            },
            elementId(...parts) {
                parts.push(this.id);
                return parts.join('__');
            },
            setPrinter(event, address) {
                event.preventDefault();
                let dialog = this.$f7.dialog;
                dialog.preloader('Testowanie drukarki...');



                let data = {
                    type: 'bluetooth',
                    printerWidthMM: 50.0,
                    printerNbrCharactersPerLine: 42,
                    id: address,
                    charsetEncoding: {
                        charsetId: 18,
                        charsetName: 'IBM852'
                    },
                };

                printer.testPrinter(
                    data,
                    () => {
                        dialog.close();
                        dialog.alert('Wydruk gotowy!');
                        this.$store.commit('printer', address);
                    },
                    error => {
                        dialog.close();
                        if(error.error==='Unable to connect to bluetooth device.')  {
                            dialog.alert('Nie można połączyć się z drukarką, sprawdź czy jest włączona i połączona z telefonem w ustawieniach Bluetooth');
                        }
                        if(error.error==='Broken pipe')  {
                            dialog.alert('Wystąpił błąd komunikacji z drukarką. Upewnij się, że durkarka jest wspierana.');
                        }
                        console.error('Printing error', (error));
                    }
                );
            },
            openBluetoothSettings() {
                NativeSettings.open({
                    optionAndroid: AndroidSettings.Bluetooth
                })
            },
            refreshPrinterSetup() {
                if(false===this.native) {
                    return;
                }
                let that = this;
                printer.requestPermissions(
                    () => {
                        that.printerNoPermissions = false;
                        printer.listPrinters(
                            result => {
                                that.printers = result;
                                if(result.length > 0 && !that.printer) {
                                    that.$store.commit('printer', result[0].address)
                                }
                            },
                            error => {
                                that.printers = [];
                                console.error(JSON.stringify(error));
                            }
                        );
                    },
                    error => {
                        that.printerNoPermissions = true;
                        console.error(JSON.stringify(error));
                    }
                );
            },
            async scan() {
                let dialog = this.$f7.dialog;
                let vue = this;
                const { camera } = await QrScanner.requestPermissions();
                if (camera === "granted") {
                    const { result } = await QrScanner.scanQrCode();
                    dialog.progress('Pobieram dane...');
                    this.$store.getters.stallageRequest(result)
                        .then( request => {
                            vue.$$('#view-home-tab').trigger('click');
                            dialog.close();
                            dialog.progress('Dane uzupełnione', 100);
                            vue.$root.$emit('stallageScanned', request.data);
                            setTimeout(() => dialog.close(), 300);
                        })
                        .catch( () => {
                            dialog.close();
                            dialog.progress('Nie znaleziono danych dla tego pokwitowania', 100);
                            setTimeout(() => dialog.close(), 1000);

                        })
                } else {
                    dialog.alert('Brak uprawnień do apartu!');
                }

            }
        },
        mounted() {
            this.id = this._uid;

            /*this.masterDetailMode = (this.$f7.width ?? 0)  >= 800;
            if(this.masterDetailMode) {
                this.$$('#view-stallages-tab').trigger('click');
            }

            if(this.$f7.device==='desktop') {
                this.$f7.theme = 'aurora';
            }*/

            let that = this;
            this.refreshPrinterSetup();

            // hide toolbar when keyboard is visible
            if(this.native) {
                Keyboard.addListener('keyboardWillShow', () => that.toolbar = false);
                Keyboard.addListener('keyboardDidHide', () => that.toolbar = true);
            }
        }
    }
</script>
<style>
    .navbar {
        background: #141337 !important;
        color: #fff
    }
    .statusbar-dark {
        background: #141337;
    }
    .statusbar-light {
        background: #fff;
    }
    .pin-keypad input {
        appearance: none;
        box-sizing: border-box;
        border: none;
        background: none;
        border-radius: 0 0 0 0;
        box-shadow: none;
        display: block;
        padding: 0 0 0 5px;
        margin: 0;
        width: 100%;
        height: 43px !important;
        color: #000;
        font-family: "Lucida Console", Monaco, monospace !important;
        letter-spacing: 10px;
        text-align: center;
        font-size: 30px !important;
        font-weight: 300;
    }
</style>
